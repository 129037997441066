<template>
  <div class="page my-page-wrap">
    <div class="inner">
      <div class="content">
        <div class="left">
          <img src="~@/assets/imgs/left.png" alt="" />
        </div>
        <div class="center main-content">
          <div class="list">
            <div class="card" v-for="item in list" :key="item.live_id" @click="cardClick(item)">
              <div class="card-left">
                <img :src="$baseRemote + item.cover_image" class="card-image" />
                <div class="card_mask_inner" v-if="item.live_id != currentPlayId">
                  <div class="play"></div>
                </div>
                <div class="tip" v-if="item.live_id == currentPlayId">
                  <span>
                    {{ movetimevalue }}
                  </span>
                </div>
              </div>
              <div class="card-bottom">
                <span>{{ item.live_title }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="~@/assets/imgs/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from "@/utils/http"

  export default {
    data() {
      return {
        pageSize: 10,
        curPage: 1,
        count: 0,

        list: [],

        vedioSrc: "",
        movetimevalue: "",
        currentPlayId: null
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      getList() {
        http({
          url: "/api/live/page",
          data: {
            page: this.curPage,
            pageSize: this.pageSize
          }
        }).then((res) => {
          this.list = res.data.list
        })

        return

        http({
          url: "/api/diyview/info",
          data: {
            page: this.curPage,
            pageSize: this.pageSize,
            name: "DIY_VIEW_RANDOM_1676855473"
          }
        }).then((res) => {
          const value = JSON.parse(res.data.value).value

          this.count = value.length
          const list = value.map((item) => item.list[0])

          console.log("list", list)

          if (this.curPage == 1) {
            this.list = list
          } else {
            this.list = this.list.concat(list)
          }
        })
      },

      handleCurrentChange(val) {
        this.curPage = val
        this.getList()
      },

      playShow(item) {
        this.vedioSrc = this.$baseRemote + item.shortv_url
      },
      timemove() {
        //定义个个时间变量
        var date = new Date()
        var year = date.getFullYear()
        var mth = date.getMonth() + 1
        var d = date.getDate()
        var h = date.getHours()
        var m = date.getMinutes()
        var s = date.getSeconds()
        var week = date.getDay()

        //定义拼接变量,变量调用转换两位数函数
        var time = year + "年" + fn(mth) + "月" + fn(d) + "日" + fn(h) + ":" + fn(m) + ":" + fn(s)

        //定义函数，将一位数"1"转为两位数"01"
        function fn(str) {
          str < 10 ? (str = "0" + str) : str
          return str
        }
        //定义定时器，调用函数，每秒刷新
        // setInterval(timemove, 1000);
        //拼接变量显示在页面
        this.movetimevalue = time
        console.log(time)
      },
      timemoveinterval() {
        this.timer = setInterval(this.timemove, 1000)
      },

      cardClick(item) {
        // 清除上一个
        this.timer && clearInterval(this.timer)
        // 开启下一个
        this.currentPlayId = item.live_id
        this.timemoveinterval()
      }
    },
    beforeDestroy() {
      this.timer && clearInterval(this.timer)
    }
  }
</script>

<style lang="scss">
  .page {
    .btn .el-button {
      width: 101px;
      height: 28px;

      background: #a20000;
      border-radius: 6px;
      border: none;
    }

    .pagination {
      .active {
        background: #a20000;
        color: white;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .page {
    .list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      box-sizing: border-box;
      .card-image {
        width: 100%;
        height: auto;
        background: #d8d8d8;
        border-radius: 8px;
      }

      .card {
        border: 1px solid transparent;
        width: 48%;
        margin: 0 1%;
        box-sizing: border-box;
        // padding: 18px;
        background-color: white;
        display: flex;
        margin-bottom: 25px;

        display: flex;
        flex-direction: column;
      }
      .card:hover {
        .card-image {
          border-color: #a20000;
          // 实现放大效果
          transform: scale(1.01);
        }
        .card-bottom {
          color: #a20000;
          font-size: 15px;
        }
      }
      .card-left {
        margin-right: 14px;
        position: relative;
        .tip {
          position: absolute;
          left: 13px;
          top: 11px;
          color: white;
        }
        .card_mask_inner {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.6);
          .play {
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-bottom: 50px solid transparent;
            border-left: 60px solid white;
          }
        }
      }

      .card-bottom {
        color: #333;
        font-size: 14px;
        margin-top: 10px;
        font-weight: normal;
        line-height: 22px;
      }

      //  窗口大于 800，字号放大
      @media screen and (min-width: 800px) {
        .card-bottom {
          margin-top: 20px;
          font-size: 26px;
          line-height: 32px;
        }
        .card:hover .card-bottom {
          font-size: 28px;
        }
      }

      //   .card-right {
      //     text-align: left;
      //   }

      //   .date-num {
      //     margin-top: 16px;
      //     color: #999999;
      //   }

      //   .subscribe {
      //     color: #666666;
      //     margin: 16px 0 14px;
      //   }

      //   .subscribe {
      //     display: -webkit-box;
      //     -webkit-box-orient: vertical;
      //     -webkit-line-clamp: 2;
      //     overflow: hidden;
      //   }
      // }

      // .pagination {
      //   margin: 20px 0;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      // }

      // .video-wrap {
      //   background-color: black;
      //   position: fixed;
      //   left: 50vw;
      //   top: 50vh;
      //   transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */

      //   .icon-close {
      //     position: absolute;
      //     right: 5px;
      //     top: 5px;
      //     width: 40px;
      //     height: 40px;
      //     z-index: 1000;
      //     cursor: pointer;
      //   }
    }
  }
</style>
